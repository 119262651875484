import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {ButtonDirective, ButtonPanelComponent, Buttons, ButtonTypes, DialogService} from '@matchsource/nmdp-ui';
import {NgIf} from '@angular/common';

@Component({
  selector: 'ms-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonPanelComponent, NgIf, ButtonDirective],
})
export class ConfirmationDialogComponent {
  @HostBinding('class')
  hostClass = 'simple-component-wrapper';

  @Input()
  identifier!: string;

  @Input()
  caption: string;

  @Input()
  cancelText: string;

  @Input()
  confirmText: string;

  @Input()
  confirmButtonType: ButtonTypes = Buttons.Action;

  @Input()
  hideCancel = false;

  constructor(private readonly dialogService: DialogService) {}

  onSave() {
    this.close(true);
  }

  onCancel() {
    this.close(false);
  }

  onClose(): void {
    this.close();
  }

  private close(data?: any) {
    this.dialogService.close(this.identifier, data);
  }
}
