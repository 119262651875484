<ms-dynamic-dialog>
  <ng-template let-data let-modal="modal">
    <ng-container
      [ngTemplateOutlet]="confirmationDialogTpl"
      [ngTemplateOutletContext]="{$implicit: applyDefaultData(data)}"
    >
    </ng-container>
    <ng-template #confirmationDialogTpl let-data>
      <ms-confirmation-dialog
        [identifier]="modal.identifier"
        [caption]="data.caption | transloco"
        [cancelText]="data.cancelText | transloco"
        [confirmText]="data.confirmText | transloco"
        [confirmButtonType]="data.confirmButtonType"
        [hideCancel]="data.hideCancel"
      >
        <span [innerHTML]="data.confirmMessage | transloco : data.confirmMessageParams | format"></span> <span class="body-message">{{data.bodyMessage}}</span>
      </ms-confirmation-dialog>
    </ng-template>
  </ng-template>
</ms-dynamic-dialog>
