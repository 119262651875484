<ngx-smart-modal
 [hideDelay]="0"
 [identifier]="id"
 customClass="draggable-modal {{customClass}}"
 [dismissable]="false"
 (onClose)="onClose()"
>
  <div class="simple-component-wrapper">
    <div class="modal-header">
      <h4 class="modal-title">{{titleText | transloco}}</h4>
    </div>
    <div class="modal-body">
      <span [innerHTML]="confirmMessage | transloco : confirmMessageParams | format"></span> <span class="body-message">{{bodyMessage}}</span>
    </div>
    <div class="modal-footer">
      <ms-button-panel size="large">
        <button msButton="secondary" (click)="onCancel()">{{secondaryButtonText | transloco}}</button>
        <button msButton [buttonType]="actionButtonType" (click)="onSave()">{{actionButtonText | transloco}}</button>
      </ms-button-panel>
    </div>
  </div>
</ngx-smart-modal>

