<div class="modal-header">
  <h4 class="modal-title">{{ caption }}</h4>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <ms-button-panel size="large">
    <button msButton="secondary" (click)="onCancel()" *ngIf="!hideCancel">{{ cancelText }}</button>
    <button msButton [buttonType]="confirmButtonType" (click)="onSave()">{{ confirmText }}</button>
  </ms-button-panel>
</div>
