import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ButtonDirective, ButtonPanelComponent, DynamicDialogComponent, ModalCloseDirective} from '@matchsource/nmdp-ui';
import {TranslocoPipe} from '@jsverse/transloco';

export interface MessageDialogData {
  headerText: string;
  messageText: string;
}

@Component({
  selector: 'ms-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicDialogComponent, ButtonPanelComponent, ButtonDirective, ModalCloseDirective, TranslocoPipe],
})
export class MessageDialogComponent {}
