<ms-dynamic-dialog customClass="message-dialog">
  <ng-template let-data>
    <div class="simple-component-wrapper">
      <div class="modal-header">
        <h4 class="modal-title">{{ data.headerText }}</h4>
      </div>
      <div class="modal-body">
        <span [innerHTML]="data.messageText"></span>
      </div>
      <div class="modal-footer">
        <ms-button-panel size="large">
          <button type="button" msButton="action" [msModalClose]="true">{{'COMMON.OK' | transloco}}</button>
        </ms-button-panel>
      </div>
    </div>
  </ng-template>
</ms-dynamic-dialog>
