import {ButtonDirective, ButtonPanelComponent, Buttons, DialogService, FormatPipe} from '@matchsource/nmdp-ui';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {TranslocoPipe} from '@jsverse/transloco';

type ActionButtonTypes = Buttons.Action | Buttons.Warning;
type ActionButtonTypesLiteral = `${ActionButtonTypes}`;

@Component({
  selector: 'ms-confirmation',
  templateUrl: './confirmation-modal.html',
  styleUrls: ['./confirmation-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgxSmartModalComponent, ButtonPanelComponent, ButtonDirective, TranslocoPipe, FormatPipe],
})
export class ConfirmationModalComponent {
  @Input()
  id = 'confirmation';

  @Input()
  bodyMessage: string;

  @Input()
  confirmMessageParams: any;

  @Input()
  titleText: string;

  @Input()
  actionButtonText: string;

  @Input()
  actionButtonType: ActionButtonTypesLiteral = Buttons.Action;

  @Input()
  secondaryButtonText: string;

  @Input()
  confirmMessage: string;

  @Input()
  customClass = '';

  @Output()
  save: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly dialogService: DialogService) {}

  onSave() {
    this.save.emit();
    this.close(true);
  }

  onCancel() {
    this.cancel.emit();
    this.close(false);
  }

  onClose(): void {
    this.closeModal.emit();
    this.close();
  }

  private close(data?: any) {
    this.dialogService.close(this.id, data);
  }
}
